import axios from "axios"

import {
  Loading,
  Message
} from 'element-ui';

const loading = {
  loadingInstance: null,
  open() {
    if (this.loadingInstance == null) {
      this.loadingInstance = Loading.service({
        target: '.main',
        text: "玩命加载中",
        background: "rgba(0, 0, 0, 0.5)"
      })
    }
  },
  close() {
    if (this.loadingInstance != null) {
      this.loadingInstance.close()
    }
    this.loadingInstance = null
  }
}
// 配置的axios实例
const request = axios.create({
  // baseURL: 'http://cujinhui.qywx.pro', // 路径
  // baseURL: 'https://cjh.xiaoxixing.cn', //测试
  // baseURL: 'https://pj.ciur.org.cn/', //测试
  // baseURL: 'https://cjhdev.xiaoxixing.cn', //正式
  // timeout: 5000 // 请求超时，5000毫秒
})

// 请求拦截器
request.interceptors.request.use(config => {
  // 加密
  loading.open()
  return config
}, error => {
  // 响应后解除加载
  loading.close()
  // 出现异常，抛出错误对象
  return Promise.reject(error)
})

// 响应拦截器
request.interceptors.response.use(response => {
  // 响应后解除加载
  loading.close()
  // 响应发送处理.data
  // console.log(response)
  console.log(response);
  return response
}, error => {
  // 响应后解除加载
  loading.close()
  // 出现异常，抛出错误对象
  Message({
    // 错误信息
    message: error.message,
    type: 'error',
    duration: 5 * 1000 // 停留时长
  })
  return Promise.reject(error)
})

// 导出
export default request
